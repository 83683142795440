* {
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    background-color: #F5F5F5;
}

body:before {
    width: 100%;
    height: 100%;
    content: ' ';
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    background: #fff;
}

a {
    text-decoration: none;
}

.page img {
    max-width: 100%;
}

.page code {
    padding: 2px 4px;
    font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
    font-size: 0.9rem;
    color: #383e41;
    background-color: #f3f6fa;
    border-radius: 0.3rem;
}

.page pre {
    padding: 0.8rem;
    margin-top: 0;
    margin-bottom: 1rem;
    font: 1rem Consolas, "Liberation Mono", Menlo, Courier, monospace;
    color: #567482;
    word-wrap: normal;
    background-color: #f3f6fa;
    border: solid 1px #dce6f0;
    border-radius: 0.3rem;
}

.page pre > code {
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
    color: #567482;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
}

.page .highlight {
    margin-bottom: 1rem;
}

.page .highlight pre {
    margin-bottom: 0;
    word-break: normal;
}

.page .highlight pre,
.page pre {
    padding: 0.8rem;
    overflow: auto;
    font-size: 0.9rem;
    line-height: 1.45;
    border-radius: 0.3rem;
}

.page pre code,
.page pre tt {
    display: inline;
    max-width: initial;
    padding: 0;
    margin: 0;
    overflow: initial;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
}

.page pre code:before,
.page pre code:after,
.page pre tt:before,
.page pre tt:after {
    content: normal;
}

.page ul,
.page ol {
    margin-top: 0;
}

.page blockquote {
    padding: 0 1rem;
    margin-left: 0;
    color: #819198;
    border-left: 0.3rem solid #dce6f0;
}

.page blockquote > :first-child {
    margin-top: 0;
}

.page blockquote > :last-child {
    margin-bottom: 0;
}

.page table {
    display: block;
    width: 100%;
    overflow: auto;
    word-break: normal;
    word-break: keep-all;
}

.page table th {
    font-weight: bold;
}

.page table th,
.page table td {
    padding: 0.5rem 1rem;
    border: 1px solid #e9ebec;
}

.page dl {
    padding: 0;
}

.page dl dt {
    padding: 0;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: bold;
}

.page dl dd {
    padding: 0;
    margin-bottom: 1rem;
}

.page hr {
    height: 2px;
    padding: 0;
    margin: 1rem 0;
    background-color: #eff0f1;
    border: 0;
}

@media screen and (min-width: 64em) {
    .page {
        margin: 0 auto;
        font-size: 0.40rem;
        color: #999;
    }
}

@media screen and (min-width: 42em) and (max-width: 64em) {
    .page {
        font-size: 0.40rem;
        color: #999;
    }
}

@media screen and (max-width: 42em) {
    .page {
        font-size: 0.28rem;
        color: #999;
    }

    iframe {
        display: none
    }
}